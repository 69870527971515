import ApiService from "@/common/api.service";
export default {
  getTags() {
    return ApiService.query("/tests/tags");
  },
  getTestList(testType, data) {
    switch (testType.toUpperCase()) {
      case "SAT":
        return ApiService.query("sats/list", data);
      case "ACT":
        return ApiService.query("acts/list", data);
      case "TOEFL":
        return ApiService.query("toefl-analysis/list", data);
      default:
        throw `getTestList: Have no the test type: ${testType}`;
    }
  },
  removeTest(testType, id) {
    switch (testType.toUpperCase()) {
      case "SAT":
        return ApiService.delete(`sats/${id}`);
      case "ACT":
        return ApiService.delete(`acts/${id}`);
      default:
        throw `getTestList: Have no the test type: ${testType}`;
    }
  },
  async getTest(testType, testTypeId) {
    switch (testType.toUpperCase()) {
      case "SAT": {
        const { sat } = await ApiService.query(`sat-editor/${testTypeId}`);
        return sat;
      }
      case "ACT": {
        const { act } = await ApiService.query(`act-editor/${testTypeId}`);
        return act;
      }
      default:
        throw `getTest: Have no the test type: ${testType}`;
    }
  },
  updateTestInfo(testType, exam_id, data) {
    switch (testType.toUpperCase()) {
      case "SAT":
        return ApiService.update("/sat-editor", exam_id, data);
      case "ACT":
        return ApiService.update("/act-editor", exam_id, data);
      default:
        throw `updateTestInfo: Have no the test type: ${testType}`;
    }
  },
  async getSubjectQuestions(testType, subjectId) {
    switch (testType.toUpperCase()) {
      case "SAT": {
        const { sat_questions } = await ApiService.query(
          `sat/${subjectId}/questions`
        );
        return sat_questions;
      }
      case "ACT": {
        const { act_questions } = await ApiService.query(
          `act/${subjectId}/questions`
        );
        return act_questions;
      }
      default:
        throw `getSubjectQuestions: Have no the test type: ${testType}`;
    }
  },
  async getPassage(testType, passageId) {
    switch (testType.toUpperCase()) {
      case "SAT": {
        const { sat_passage } = await ApiService.query(
          `sat/passage/${passageId}`
        );
        return sat_passage;
      }
      case "ACT": {
        const { act_passage } = await ApiService.query(
          `act/passage/${passageId}`
        );
        return act_passage;
      }
      default:
        throw `getPassage: Have no the test type: ${testType}`;
    }
  },
  async getSubjectPassages(testType, subjectId, search) {
    const searchBody = search ? `?search=${search}` : "";
    switch (testType.toUpperCase()) {
      case "SAT": {
        const { sat_passages } = await ApiService.query(
          `sat/${subjectId}/passages${searchBody}`
        );
        return sat_passages;
      }
      case "ACT": {
        const { act_passages } = await ApiService.query(
          `act/${subjectId}/passages${searchBody}`
        );
        return act_passages;
      }
      default:
        throw `getSubjectPassages: Have no the test type: ${testType}`;
    }
  },
  async createSubjectPassage(testType, satSubjectId, passage) {
    switch (testType.toUpperCase()) {
      case "SAT": {
        const { sat_passage } = await ApiService.post(
          `${testType}/${satSubjectId}/passage`,
          passage
        );
        return sat_passage;
      }
      case "ACT": {
        const { act_passage } = await ApiService.post(
          `${testType}/${satSubjectId}/passage`,
          passage
        );
        return act_passage;
      }
      default:
        throw `createSubjectPassage: Have no the test type: ${testType}`;
    }
  },
  async createSubjectQuestion(testType, sectionId, question) {
    switch (testType.toUpperCase()) {
      case "SAT": {
        const { sat_question } = await ApiService.post(
          `sat/${sectionId}/question`,
          question
        );
        return sat_question;
      }
      case "ACT": {
        const { act_question } = await ApiService.post(
          `act/${sectionId}/question`,
          question
        );
        return act_question;
      }
      default:
        throw `createSubjectPassage: Have no the test type: ${testType}`;
    }
  },
  async updateSubjectPassage(testType, passageId, passage) {
    switch (testType.toUpperCase()) {
      case "SAT": {
        const { sat_passage } = await ApiService.patch(
          `sat/passage/${passageId}`,
          passage
        );
        return sat_passage;
      }
      case "ACT": {
        const { act_passage } = await ApiService.patch(
          `act/passage/${passageId}`,
          passage
        );
        return act_passage;
      }
      default:
        throw `updateSubjectPassage: Have no the test type: ${testType}`;
    }
  },
  getTestBooks() {
    return ApiService.query("test-books");
  },
  async getQuestion(testType, questionId) {
    switch (testType.toUpperCase()) {
      case "SAT": {
        const { sat_question } = await ApiService.query(
          `/sat/question/${questionId}`
        );
        return sat_question;
      }
      case "ACT": {
        const { act_question } = await ApiService.query(
          `/act/question/${questionId}`
        );
        return act_question;
      }
      default:
        throw `getQuestion: Have no the test type: ${testType}`;
    }
  },
  async getQuestionNew(testType, questionId) {
    switch (testType.toUpperCase()) {
      case "SAT": {
        const res = await ApiService.query(`/sat/question/${questionId}`);
        return res;
      }
      case "ACT": {
        const res = await ApiService.query(`/act/question/${questionId}`);
        return res;
      }
      default:
        throw `getQuestion: Have no the test type: ${testType}`;
    }
  },
  updateQuestion(testType, questionId, question) {
    switch (testType.toUpperCase()) {
      case "SAT":
        return ApiService.patch(`sat/question/${questionId}`, question);
      case "ACT":
        return ApiService.patch(`act/question/${questionId}`, question);
      default:
        throw `createSubjectPassage: Have no the test type: ${testType}`;
    }
  },
  batchUpdateQuestion(testType, questions) {
    switch (testType.toUpperCase()) {
      case "SAT": {
        const sat_questions = questions.map(question => ({
          ...question,
          sat_question_id: question.id
        }));
        return ApiService.patch("sat/question/batch", { sat_questions });
      }
      case "ACT": {
        const act_questions = questions.map(question => ({
          ...question,
          act_question_id: question.id
        }));
        return ApiService.patch("act/question/batch", { act_questions });
      }
      default:
        throw `createSubjectPassage: Have no the test type: ${testType}`;
    }
  },
  deleteQuestion(testType, questionId) {
    switch (testType.toUpperCase()) {
      case "SAT":
        return ApiService.delete(`sat/question/${questionId}`);
      case "ACT":
        return ApiService.delete(`act/question/${questionId}`);
      default:
        throw `deleteQuestion: Have no the test type: ${testType}`;
    }
  },
  deletePassage(testType, passageId) {
    switch (testType.toUpperCase()) {
      case "SAT":
        return ApiService.delete(`sat/passage/${passageId}`);
      case "ACT":
        return ApiService.delete(`act/passage/${passageId}`);
      default:
        throw `deletePassage: Have no the test type: ${testType}`;
    }
  },
  getSats() {
    return ApiService.query("sats");
  },
  getSatPractices() {
    return ApiService.query("sats");
  },
  updateSatTest(satId, satTest) {
    return ApiService.patch(`sats/${satId}/info`, satTest);
  },
  getActs() {
    return ApiService.query("acts");
  },
  getToefl() {
    return ApiService.query("/toefl-analysis/list");
  },
  getTeacherStudents(data) {
    return ApiService.query("/teacher/students", data);
  },
  getActAnalysis(data) {
    return ApiService.query("/act-analysis", data);
  },
  getSatAnalysis(data) {
    return ApiService.query("/sat-analysis", data);
  },
  getAnalysis(data) {
    return ApiService.query("/toefl-analysis", data);
  },
  getExamId(type, data) {
    return ApiService.query(type + "/get-exam-id", data);
  },
  getRes(type, user_exam_id) {
    return ApiService.get(type + "/transcript", user_exam_id);
  },
  getExamInfo(exam_id) {
    return ApiService.query("sats/exam/" + exam_id);
  },
  getNewExam(exam_id, data) {
    return ApiService.post("sats/questions/" + exam_id, data);
  },
  getOldExam(user_exam_id) {
    return ApiService.query("sats/answer/" + user_exam_id);
  },
  gradeScore(user_exam_id, data) {
    return ApiService.update("sats/answer", user_exam_id, data);
  },
  getStudents() {
    return ApiService.query("students");
  },
  getResults(exam_id, data) {
    return ApiService.query("sats/student/tests/" + exam_id, data);
  },

  getAnswers(exam_id) {
    return ApiService.get(`sats/${exam_id}/answers`);
  },
  createAnswers(data) {
    return ApiService.post("sats", data);
  },
  updateAnswers(exam_id, data) {
    return ApiService.update(`sats/${exam_id}`, "answers", data);
  },
  getType(exam_id) {
    return ApiService.get(`sats/${exam_id}`, "questions");
  },
  updateType(exam_id, data) {
    return ApiService.update(`sats/${exam_id}`, "questions", data);
  },
  getInfo(exam_id) {
    return ApiService.query(`sats/${exam_id}/info`);
  },
  getScoreConversion(exam_id) {
    return ApiService.query(`sats/${exam_id}/score-conversion`);
  },
  updateScoreConversion(exam_id, data) {
    return ApiService.update(`sats/${exam_id}`, "score-conversion", data);
  },
  getSubscoreConversion(exam_id) {
    return ApiService.query(`sats/${exam_id}/subscore-conversion`);
  },
  updateSubscoreConversion(exam_id, data) {
    return ApiService.update(`sats/${exam_id}`, "subscore-conversion", data);
  },
  getCrossTestConversion(exam_id) {
    return ApiService.query(`sats/${exam_id}/cross-test-conversion`);
  },
  updateCrossTestConversion(exam_id, data) {
    return ApiService.update(`sats/${exam_id}`, "cross-test-conversion", data);
  },
  sendResultEmail(data) {
    return ApiService.post("/tests/graded-reminder", data);
  },
  getClasses() {
    return ApiService.query("/session-classes/students");
  },
  createAssignTestToClass(classId, test) {
    return ApiService.post(
      `/session-classes/${classId}/create-or-update-and-assign-test`,
      { session_class_assign_tests: [test] }
    );
  },
  getAllStudents() {
    return ApiService.query("/admin/students");
  },
  getFile(id) {
    return ApiService.query("/assign-tests/download/" + id);
  },
  // sendTestEmail(data) {
  //   return ApiService.post("/assign-tests/students", data);
  // },
  sendTestEmail(data) {
    return ApiService.post("/assign-tests", data);
  },
  sendAndSetExam(id, data) {
    return ApiService.post(`/session-classes/${id}/distributing-assigned-test`, data);
  },
  sendExamMail(id, data){
    return ApiService.post(`/session-classes/${id}/mail-distributing-assigned-test`, data);
  },
  sendSATMocksEmail(data) {
    return ApiService.post("/sat-mocks/assign-tests", data);
  },
  setTeacherExams(data) {
    return ApiService.post("/assign-tests/session-class", data);
  },
  getTeacherClassExams(examId){
    return ApiService.query(`/exam/${examId}/map-test-id`);
  },
  getUserExamInfo(userExamId) {
    return ApiService.query("/user-exam/" + userExamId);
  },
  getGroupCourses(data) {
    return ApiService.query("student-class-ticket-exams/session-class", data);
  },
  getScoreList(type, exam_id, data) {
    return ApiService.query(type + "/exam/" + exam_id + "/score-list", data);
  },
  removeUserExam(exam_id) {
    return ApiService.delete("user-exam/" + exam_id);
  },
  // getUserAnswer(answerId) {
  //   return ApiService.query(`sats/exp/answer/${answerId}`);
  // }
  getUserAnswer(testType, answerId) {
    switch (testType.toUpperCase()) {
      case "SAT":
        return ApiService.query(`/sat/answer/${answerId}`);
      case "ACT":
        return ApiService.query(`/act/answer/${answerId}`);
      default:
        throw `getTestList: Have no the test type: ${testType}`;
    }
  },
  getTestFile(token) {
    return ApiService.download(`/exam/download/${token}`);
  },
  getStudentsExams(){
    return ApiService.query("/student-class-ticket-exams");
  },
  getStudentExams(id){
    return ApiService.query(`students/${id}/exams`);
  },
  getClassesExams() {
    return ApiService.query("students/session-exams");
  },
  getUnitExams(data) {
    return ApiService.query("students/session-class-sat-mocks-unit-exams", data);
  },
  getStudentUserExams(id, data) {
    return ApiService.query(`student-class-tickets/${id}/student-class-ticket-exams`, data);
  },
  getStudentsExamsFromSAT(id, data) {
    return ApiService.query(`sat-mocks/users/${id}/user-exams`, data);
  },
  reSetTest(sessionClassAssignTest, data) {
    return ApiService.post(
      `session-class-assign-tests/${sessionClassAssignTest}/student-class-ticket-exams`,
      data
    );
  },
  refreshAnswer(id) {
    return ApiService.patch(`/sat/refresh-order-and-correct/${id}`);
  },
  studentGetExams(){
    return ApiService.query("assign-tests/required");
  },
  postFeedback(data){
    return ApiService.post("assign-tests/required",data);
  },
  getStudentsClassesTest(data) {
    return ApiService.query("student-class-test-search", data);
  },
  updateAllSATStudents(id){
    return ApiService.patch(`sat/update-students-scores/${id}`);
  },
  updateAllACTStudents(id){
    return ApiService.patch(`act/update-students-scores/${id}`);
  }
};
