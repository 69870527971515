<template>
  <el-table :data="sections" style="width: 100%;margin-bottom:30px" border>
    <el-table-column label="Section" width="250">
      <template slot-scope="scope">
        <b>{{ scope.row.name }}</b>
      </template>
    </el-table-column>
    <el-table-column prop="passage" label="Passages" width="180">
      <template slot-scope="scope">
        <span
          v-if="
            !testQuestionAndPassagesCount(testType, scope.row.name, 'passages')
          "
          >-</span
        >
        <router-link
          v-else
          :to="{
            name: 'Section',
            params: {
              testType,
              testTypeId,
              sectionId: scope.row.id
            }
          }"
        >
          <span
            v-if="
              testQuestionAndPassagesCount(
                testType,
                scope.row.name,
                'passages'
              ) === scope.row.passages.length
            "
          >
            {{ scope.row.passages.length }}
          </span>
          <span v-else style="color: red">
            {{
              `${scope.row.passages.length}/${testQuestionAndPassagesCount(
                testType,
                scope.row.name,
                "passages"
              )}`
            }}
          </span>
        </router-link>
      </template>
    </el-table-column>
    <el-table-column prop="passage" label="Answer" width="180">
      <template slot-scope="scope">
        <router-link
          :to="{
            name: 'Question',
            params: {
              testType,
              testTypeId,
              sectionId: scope.row.id
            }
          }"
        >
          <span
            v-if="
              testQuestionAndPassagesCount(
                testType,
                scope.row.name,
                'questions'
              ) === scope.row.questions.length
            "
          >
            {{ scope.row.questions.length }}
          </span>
          <span v-else style="color: red">
            {{
              `${scope.row.questions.length}/${testQuestionAndPassagesCount(
                testType,
                scope.row.name,
                "questions"
              )}`
            }}
          </span>
        </router-link>
      </template>
    </el-table-column>
    <el-table-column prop="passage" label="Question" width="180">
      <template slot-scope="scope">
        <router-link
          :to="{
            name: 'Question',
            params: {
              testType,
              testTypeId,
              sectionId: scope.row.id
            }
          }"
        >
          <span
            v-if="
              testQuestionAndPassagesCount(
                testType,
                scope.row.name,
                'questions'
              ) ===
                scope.row.questions.filter(
                  question => question.content || question.options.length !== 0
                ).length
            "
          >
            {{ scope.row.questions.length }}
          </span>
          <span v-else style="color: red">
            {{
              `${
                scope.row.questions.filter(
                  question => question.content || question.options.length !== 0
                ).length
              }/${testQuestionAndPassagesCount(
                testType,
                scope.row.name,
                "questions"
              )}`
            }}
          </span>
        </router-link>
      </template>
    </el-table-column>
    <el-table-column prop="passage" label="Explain" width="180">
      <template slot-scope="scope">
        <router-link
          :to="{
            name: 'Question',
            params: {
              testType,
              testTypeId,
              sectionId: scope.row.id
            }
          }"
        >
          <span
            v-if="
              testQuestionAndPassagesCount(
                testType,
                scope.row.name,
                'questions'
              ) === scope.row.questions.filter(question => question.exp).length
            "
          >
            {{ scope.row.questions.length }}
          </span>
          <span v-else style="color: red">
            {{
              `${
                scope.row.questions.filter(question => question.exp).length
              }/${testQuestionAndPassagesCount(
                testType,
                scope.row.name,
                "questions"
              )}`
            }}
          </span>
        </router-link>
      </template>
    </el-table-column>
    <el-table-column prop="date" label="Date">
      <template slot-scope="scope">
        {{ instant.formatDate(scope.row.date) }}
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import testsApi from "@/apis/tests.js";
import { instant } from "@ivy-way/material";

export default {
  props: ["testType", "testTypeId"],
  computed: {
    instant() {
      return instant;
    }
  },
  data() {
    return {
      test: {},
      sections: []
    };
  },
  async created() {
    this.test = await testsApi.getTest(this.testType, this.testTypeId);
    this.sections = this.test.subjects;
  },
  methods: {
    testQuestionAndPassagesCount(testType, sectionName, item) {
      const testInformation = {
        SAT: {
          reading: { passages: 5, questions: 52 },
          writing: { passages: 4, questions: 44 },
          math: { questions: 20 },
          "math calculator": { questions: 38 },
          essay: { passages: 1, questions: 1 }
        },
        ACT: {
          english: { passages: 5, questions: 75 },
          math: { questions: 60 },
          reading: { passages: 4, questions: 40 },
          science: { passages: 7, questions: 40 },
          essay: { passages: 1, questions: 1 }
        }
      };
      return testInformation[testType.toUpperCase()][sectionName][item];
    }
  }
};
</script>

<style></style>
