<template>
  <div>
    <!-- TEST File -->
    <div style="padding-bottom:0">
      <hr />
      <div style="padding-bottom:0">
        <div class="title">
          <h4 class="title-text">
            TEST INFORMATION
          </h4>
        </div>
        <div class="title-text">
          <div class="test_input">
            <div class="row input-line">
              <div class="col-md-1">
                <span class="input-title">
                  <b>Test</b>
                </span>
              </div>
              <div class="col-md-11">
                <input
                  class="form-control title-input"
                  type="text"
                  v-model="info['full_name']"
                  :disabled="cannotEdit"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="title-text">
          <div class="test_input">
            <div class="row input-line">
              <div class="col-md-1">
                <span class="input-title">
                  <b>Test ID</b>
                </span>
              </div>
              <div class="col-md-11">
                <input
                  class="form-control title-input"
                  type="text"
                  v-model="info['title']"
                  :disabled="cannotEdit"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="title-text">
          <div class="test_input">
            <div class="row input-line">
              <div class="col-md-1">
                <span class="input-title">
                  <b>Book</b>
                </span>
              </div>
              <div class="col-md-11">
                <el-select
                  v-model="info.test_book_ids"
                  multiple
                  style="width:100%"
                  :disabled="cannotEdit"
                >
                  <el-option
                    v-for="book in books"
                    :key="book.id"
                    :label="book.title"
                    :value="book.id"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="title">
        <h4 class="title-text">
          TEST File
        </h4>
      </div>
      <div class="title-text">
        <div class="test_input">
          <div class="row input-line">
            <div
              class="col-md-12"
              v-loading="fileLoading"
              element-loading-text="Uploading"
            >
              <div style="margin-bottom:10px">
                <div
                  v-if="
                    info.url !== null &&
                      info.url.test !== null &&
                      info.url.test.indexOf('https://') !== -1
                  "
                >
                  <el-button size="mini"
                    type="success" style="margin-bottom:10px">
                    <a :href="info.url.test" target="_blank" style="color: white !important;">
                      <i class="fas fa-download"></i>
                      {{ info["full_name"] }} ({{ info["title"] }})
                      
                    </a>
                  </el-button>
                  <el-button
                    v-show="!displayUploadTest && !cannotEdit"
                    @click="displayUploadTest = true"
                    size="mini"
                    type="text"
                  >
                    <i class="fa fa-edit"></i> 編輯已上傳文件
                  </el-button>
                  <el-button
                    v-show="displayUploadTest && !cannotEdit"
                    @click="displayUploadTest = false"
                    size="mini"
                    style="color:red"
                    type="text"
                  >
                    <i class="fa fa-times"></i> 取消編輯
                  </el-button>
                </div>
                <div v-else>
                  <h6>暫無考卷。</h6>
                </div>
              </div>
              <el-upload
                v-if="!cannotEdit && (!info.url.test || displayUploadTest)"
                class="upload-demo"
                drag
                :action="postUrl"
                :headers="token"
                :data="{ type: 'tests/ACT' }"
                name="document"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
              >
                <div>
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">
                    將文件拖到此處，或<em>點擊上傳</em>
                  </div>
                </div>
              </el-upload>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import JwtService from "@/common/jwt.service";
import testsApi from "@/apis/tests.js";

export default {
  components: {},

  mixins: [],

  props: ["info", "cannotEdit"],
  data() {
    return {
      token: { Authorization: `Bearer ${JwtService.getToken()}` },
      fileLoading: false,
      books: [],
      displayUploadTest: false
    };
  },
  computed: {
    postUrl() {
      return process.env.VUE_APP_BACKEND_URL + "document";
    }
  },
  watch: {},

  mounted() {
    this.getTestBooks();
  },

  methods: {
    handleAvatarSuccess(res) {
      this.info.url = { test: res.document.url };
      this.fileLoading = false;
      this.displayUploadTest = false;
    },
    beforeAvatarUpload() {
      let canUpload = true;
      this.fileLoading = true;
      return canUpload;
    },
    async getTestBooks() {
      const res = await testsApi.getTestBooks();
      this.books = res.test_books;
    }
  }
};
</script>

<style scoped>
.answers {
  padding: 20px 0;
}
.action-btns {
  z-index: 9999999;
  position: fixed;
  top: 120px;
  right: 0px;
}
hr {
  height: 2px;
  background-color: #43a06b;
}
.action-btns {
  margin-bottom: 15px;
}
h4.title-text {
  color: #43a06b;
  margin-right: 10px;
  line-height: 44px;
  height: 44px;
  margin-bottom: 0;
}
.input-line {
  margin-bottom: 20px;
}
.title {
  display: flex;
  margin-bottom: 10px;
}
</style>
